<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.validate_email')}}</h1>
      </header>

      <div class="mb-4">
        <p v-if="successMessage">
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </p>

        <p v-if="errors.length">
          <v-alert
            text
            dense
            type="error"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'ValidateEmail',
  data: () => ({
    successMessage: null,
    errors: [],
    loading: false
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.checkToken()
    document.title = config.title + ' - ' + this.$t('message.validate_token')
  },
  methods: {
    checkToken () {
      const token = this.$route.query.token

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/check-email-token', {
          params: {
            token: token
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data
              this.errors = []
              this.loading = false

              setTimeout(() => {
                this.$store.dispatch('logout')
              }, 3000)
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
            this.loading = false
          }
        })
    }
  }
}
</script>
